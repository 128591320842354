import $ from 'jquery';
import 'bootstrap';
import 'lightgallery/src/js/lightgallery';
require('./plugins/viewport');

// Import all images dynamically
const images = require.context('../images', true, /\.(png|jpe?g|svg|gif|webp)$/);
images.keys().forEach(images);


/**********************
 * Apollo Tracker
 **********************/
/*
function initApollo() {
    const cacheBuster = Math.random().toString(36).substring(7);
    const script = document.createElement("script");
    script.src = "https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=" + cacheBuster;
    script.async = true;
    script.defer = true;
    script.onload = function () {
        if (window.trackingFunctions && window.trackingFunctions.onLoad) {
            window.trackingFunctions.onLoad({ appId: "6633345144fe6e0302e37c63" });
        }
    };
    document.head.appendChild(script);
}

if ("requestIdleCallback" in window) {
    requestIdleCallback(initApollo);
} else {
    window.addEventListener("load", initApollo);
}
*/


$(function () {
    let $body = $("body");
    const navBarHeight = 100;
    const titles = document.querySelectorAll(".full-width-title");

    // 400 Error
    /* initApollo(); */

    /***********************
     * LightGallery *
     ************************/
    $(".lightgallery").lightGallery({
        selector: 'a'
    });

    /*********************
     * Language Selector *
     *********************/
    $("#languageDropdownMenuLink").dropdown()

    $body.on('click', '#navbar-menu-button', function () {
        $body.toggleClass("body-no-scroll");
        $(this).toggleClass("is-active");
        $("#navbarSupportedContent").toggleClass("show");
    });

    /***********************
     * Scrolling by Anchors *
     ************************/

    $("a[href^='#']").click(function (e) {

        e.preventDefault();
        let position = $($(this).attr("href")).offset().top;
        $("body, html").animate({
            scrollTop: position - navBarHeight
        });
        if ($body.width() <= 992 && $("#navbarSupportedContent").hasClass("show")) {
            $("#navbar-menu-button").trigger("click")
        }
    });


    /**********************
     Line-title animation
    ***********************/
    if (titles.length === 1) {
        titles[0].classList.add("animate");
    } else {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate");
                    } else {
                        entry.target.classList.remove("animate");
                    }
                });
            },
            {
                root: null,
                threshold: 0.1,
            }
        );

        titles.forEach((title) => observer.observe(title));
    }

    /**************
     * LAZY IMAGE
     **************/
    $('.lazy-image').each(function () {

        const $img = $(this).find('img');

        const trackImageVisibility = function () {

            if ($img.isInViewport() && !$img.data('loading') && !$img.data('loaded')) {

                $(window).off('scroll', trackImageVisibility);

                $img
                    .on('load', function () {
                        $(this).next('svg').remove();
                    })
                    .data('loading', true)
                    .attr('src', $img.data('lazy-src'))
                    .data('loaded', true);

            }
        };

        $(window).on('scroll', trackImageVisibility).trigger('scroll');
    })

});



